// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



:root {
  --primary-color: #4ca30d;
  --secondary-color: #667085;
  --font-inter: "Inter", sans-serif;
  --ui-gray: #f9fafb;
  --bg-blur: #f3fee7;
  --text-color: #667085;
}

.styled {
  display: "none !important";
}

body {
  font-family: var(--font-inter) !important;
  overflow-x: hidden !important;
  
}

.asterisk::after {
  content: "*";
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;;;AAIA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,iCAAiC;EACjC,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yCAAyC;EACzC,6BAA6B;;AAE/B;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap\");\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\n\n\n:root {\n  --primary-color: #4ca30d;\n  --secondary-color: #667085;\n  --font-inter: \"Inter\", sans-serif;\n  --ui-gray: #f9fafb;\n  --bg-blur: #f3fee7;\n  --text-color: #667085;\n}\n\n.styled {\n  display: \"none !important\";\n}\n\nbody {\n  font-family: var(--font-inter) !important;\n  overflow-x: hidden !important;\n  \n}\n\n.asterisk::after {\n  content: \"*\";\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
